import { assertNever } from "@hex/common";
import React, { HTMLProps } from "react";
import styled, { css } from "styled-components";

type variant = "h1" | "h2" | "h3" | "h4" | "h5";

export interface HeadingProps
  extends Omit<HTMLProps<HTMLHeadingElement>, "ref" | "as"> {
  /** Required: the semantic html header and corresponding styles */
  renderAs: variant;
  /** Optional: override header styles with a different H style while maintaining semantic html with renderAs */
  styleAs?: variant;
}

export const HeadingOneStyles = css`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.HEADING_ONE};
  letter-spacing: ${({ theme }) => theme.letterSpacing.DISPLAY};
  line-height: 36px;
`;

export const HeadingTwoStyles = css`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.HEADING_TWO};
  letter-spacing: ${({ theme }) => theme.letterSpacing.HEADING};
  line-height: 31px;
`;

export const HeadingThreeStyles = css`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.HEADING_THREE};
  letter-spacing: ${({ theme }) => theme.letterSpacing.HEADING};
  line-height: 26px;
`;

export const HeadingFourStyles = css`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.HEADING_FOUR};
  letter-spacing: ${({ theme }) => theme.letterSpacing.HEADING};
  line-height: 21px;
`;

export const HeadingFiveStyles = css`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-weight: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
  font-size: ${({ theme }) => theme.fontSize.HEADING_FIVE};
  line-height: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
`;

interface HeadingStyleProps {
  $styleAs: variant;
}

const HeadingInternal = styled.h1<HeadingStyleProps>`
  margin: 0;
  ${({ $styleAs }) => {
    switch ($styleAs) {
      case "h1":
        return HeadingOneStyles;
      case "h2":
        return HeadingTwoStyles;
      case "h3":
        return HeadingThreeStyles;
      case "h4":
        return HeadingFourStyles;
      case "h5":
        return HeadingFiveStyles;
      default:
        assertNever($styleAs, $styleAs);
    }
  }}
`;

export const Heading: React.ComponentType<HeadingProps> = React.forwardRef<
  HTMLHeadingElement,
  HeadingProps
>(function Heading({ children, renderAs, styleAs, ...htmlProps }, ref) {
  return (
    <HeadingInternal
      ref={ref}
      $styleAs={styleAs ?? renderAs}
      as={renderAs}
      {...htmlProps}
    >
      {children}
    </HeadingInternal>
  );
});
